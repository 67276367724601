import React, { useState, useEffect } from 'react'
import Footer from '../common/Footer'
import { Link } from 'react-router-dom'
import HeaderSales from '../common/HeaderSales';
import axios from 'axios';
import * as Constants from "../Constants/index"
import swal from 'sweetalert';
import { saveAs } from 'file-saver';
import Oval from '../../loader/CircleLoade';
import ResponsivePagination from 'react-responsive-pagination';
export default function SalesTopPerformanceReport() {
    const [results, setResults] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [limit] = useState('10')
    const [totalPages, setTotalPages] = useState();
    const [searchRecord, setSearchRecord] = useState()
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        let token = localStorage.getItem('token');
        try {
            setLoading(true)
            axios.get(Constants.baseUrl + `reports/topPerformer/list?userType=MEMBER&sortBy=totalPoints&sortDsc=true&limit=${limit}&page=${currentPage}`, {

                headers: {
                    Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                    appVersion: Constants.av,
                    platform: Constants.pt,
                    company: Constants.company,
                    program: Constants.program,
                }
            })
                .then((res) => {
                    if (res.status === 201) {
                        if (res.data.code === 10001) {
                            setResults(res.data.output.results)
                            setTotalPages(res.data.output.totalPages);
                            setSearchRecord()
                            setLoading(false)
                        } else if (res.data.code === 12002) {
                            setSearchRecord(res.data.output.results)
                            setTotalPages(res.data.output.totalPages);
                            setLoading(false)
                        } else {
                            swal({
                                text: res.data.msg,
                                icon: 'error',
                                timer: 2000,
                                buttons: false,
                            })
                        }
                    } else {
                        swal({
                            text: res.data.msg,
                            icon: 'error',
                            timer: 2000,
                            buttons: false,
                        })
                    }
                })

        } catch (error) {
            console.log(error)
        }
    }, [currentPage])

    const download = () => {
        let token = localStorage.getItem('token');
        try {
            axios.get(Constants.baseUrl + `reports/download/topPerformerreport?userType=MEMBER`, {

                headers: {
                    Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                    appVersion: Constants.av,
                    platform: Constants.pt,
                    company: Constants.company,
                    program: Constants.program,
                },
                responseType: 'blob', // Set the responseType to 'blob'

            })
                .then((res) => {
                    if (res.status === 200) {

                        const fileData = new Blob([res.data], { type: 'text/csv' });
                        saveAs(fileData, 'TopPerformerReports.csv');
                    } else {
                        swal({
                            text: res.data.msg,
                            // icon: 'error',
                            timer: 2000,
                            buttons: false,
                        })
                    }
                })
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            {loading ? <Oval /> : null}
            <HeaderSales />
            <section class="blog-area pt182">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 tab mb-20">
                            <ul class="nav nav-tabs" role="tablist">
                                <li role="presentation" class="active"><Link to="#Section1" role="tab" data-toggle="tab"> Top Performance Report</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <div class="all-single-blog rpt">
                                {/* <!-- <div class="row clearfix mb-15">
                                        <div class="col-md-3 col-sm-12 col-xs-12">
                                            <div class="form-group has-float-label style-two">
                                                <label>From Date<sup>*</sup> </label>
                                                <input type="text" class="form-control" id="Fromdate" />
                                            </div>
                                        </div>
                                        <div class="col-md-3 col-sm-12 col-xs-12">
                                            <div class="form-group has-float-label style-two">
                                                <label>To Date<sup>*</sup> </label>
                                                <input type="text" class="form-control" id="Todate" />
                                            </div>
                                        </div>


                                        <div class="col-md-3 col-sm-12 col-xs-12">
                                            <div class="form-group has-float-label style-two">
                                                <label>Name / Mobile No :<sup>*</sup> </label>
                                                <input type="text" class="form-control" />
                                            </div>
                                        </div>
                                        <div class="col-md-3 col-sm-3 col-xs-12">
                                            <div class="form-group has-float-label style-two">
                                                <label class="invisible" style="width:100%">hidden</label>
                                                <input type="button" class="btn login_btn" value="Search" id="btnSearch">
                                            </div>
                                        </div>
                                    </div>
							 --> */}

                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="carrd1">
                                            <div class="card-header card-header-warning">
                                                <h4 class="card-title"> Top Performance Report
                                                    {searchRecord === null ? '' :
                                                        <Link to="#" class="pull-right" onClick={download}><i class="fa fa-file-excel-o"></i> Export</Link>}
                                                </h4>
                                            </div>
                                            <div class="card-body table-responsive">
                                                <table class="table table-striped mb_01" id="table-to-xls">
                                                    <thead>
                                                        <tr>
                                                            <th>S.No.</th>
                                                            <th>Name</th>
                                                            <th>Mobile</th>
                                                            <th>SH Name</th>
                                                            <th>Sales Name</th>
                                                            <th>Sales Mobile</th>
                                                            <th>State</th>
                                                            <th>District</th>
                                                            <th>City</th>
                                                            <th>Total Points</th>
                                                        </tr>
                                                    </thead>
                                                    {searchRecord === null ? <div className='searchRecord'><p className='no-record'>No record found!</p></div> :

                                                        <tbody>
                                                            {results.map((item, index) => {
                                                                const serialNumber = (currentPage - 1) * limit + index + 1;
                                                                return (
                                                                    <>
                                                                        <tr>
                                                                            <td>{serialNumber}</td>
                                                                            <td>{item.name}</td>
                                                                            <td>{item.mobile}</td>
                                                                            <td>{item.shName}</td>
                                                                            <td>{item.parentName}</td>
                                                                            <td>{item.parentMobile}</td>
                                                                            <td>{item.addressWork.workState}</td>
                                                                            <td>{item.addressWork.workdistrict}</td>
                                                                            <td>{item.addressWork.workCity}</td>
                                                                            <td>{item.totalPoints}</td>
                                                                        </tr>
                                                                    </>
                                                                )
                                                            })}

                                                        </tbody>}

                                                </table>
                                                {totalPages > 1 && (
                                                    <ResponsivePagination
                                                        current={currentPage}
                                                        total={totalPages}
                                                        onPageChange={(page) => setCurrentPage(page)}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
