import React, { useState, useEffect } from 'react'
import HeaderSales from '../common/HeaderSales';
import Footer from '../common/Footer'
import { Link } from 'react-router-dom'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import axios from 'axios';
import * as Constants from "../Constants/index"
import swal from 'sweetalert';
import ResponsivePagination from 'react-responsive-pagination';
import { saveAs } from 'file-saver';
import Oval from '../../loader/CircleLoade';
export default function SalesTicketReport() {
	const [fromDate, setFromDate] = useState('');
	const [toDate, setToDate] = useState('');
	const [results, setResults] = useState([])
	const [totalPages, setTotalPages] = useState();
	const [currentPage, setCurrentPage] = useState(1);
	const [limit] = useState('10')
	const [searchRecord, setSearchRecord] = useState()
	const [selectStatus, setSelectStatus] = useState('')
	const [loading, setLoading] = useState(false);


	// const dateString = fromDate;
	// const formattedDate = moment(dateString).format('YYYY-MM-DD HH:mm:ss');
	// const dateString2 = toDate;
	// const formattedDate2 = moment(dateString2).format('YYYY-MM-DD HH:mm:ss');
	// const start = formattedDate.replace(/:/g, '%3A')
	// const end = formattedDate2.replace(/:/g, '%3A')

	const formattedDate = moment(fromDate).format('YYYY-MM-DD HH:mm:ss');
	const formattedDate2 = moment(toDate).format('YYYY-MM-DD 23:59:59');
	const start = formattedDate.replace(/:/g, '%3A')
	const end = formattedDate2.replace(/:/g, '%3A')


	useEffect(() => {

		if (currentPage !== 1) {
			salesTicketReport(selectStatus);
		} else {
			salesTicketReport('');
		}

	}, [currentPage])

	const salesTicketReport = (userName) => {
		let token = localStorage.getItem('token');

		try {
			const searchFromDate = fromDate === '' ? '' : `&start=${start}`
			const searchToDate = toDate === '' ? '' : `&end=${end}`
			const searchStatus = userName === '' ? '' : `status=${userName}`
			if (fromDate !== '' && toDate === '') {
				swal({
					text: 'Please select to date',
					icon: 'error',
					timer: 2000,
					buttons: false,
				});
				return; // Return early if reason is not selected when status is 'reject'
			}
			if (fromDate === '' && toDate !== '') {
				swal({
					text: 'Please select from date',
					icon: 'error',
					timer: 2000,
					buttons: false,
				});
				return; // Return early if reason is not selected when status is 'reject'
			}
			if (fromDate !== '' && toDate !== '' && new Date(fromDate) > new Date(toDate)) {
				swal({
					text: 'From date should be less than to date',
					icon: 'error',
					timer: 2000,
					buttons: false,
				});
				return; // Return early if "from" date is greater than or equal to "to" date
			}
			setLoading(true)
			axios.get(Constants.baseUrl + `reports/raiseTicket/list?${searchStatus}${searchFromDate}${searchToDate}&sortBy=createdDateTime&sortAsc=false&limit=${limit}&page=${currentPage}`, {

				headers: {
					Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
					appVersion: Constants.av,
					platform: Constants.pt,
					company: Constants.company,
					program: Constants.program,
				}
			})
				.then((res) => {
					if (res.status === 201) {
						if (res.data.code === 10001) {
							setResults(res.data.output.results)
							setTotalPages(res.data.output.totalPages);
							setSearchRecord()
							setLoading(false)
						} else if (res.data.code === 12002) {
							setSearchRecord(res.data.output.results)
							setTotalPages(res.data.output.totalPages);
							setLoading(false)
						} else {
							swal({
								text: res.data.msg,
								icon: 'error',
								timer: 2000,
								buttons: false,
							})
						}
					} else {
						swal({
							text: res.data.msg,
							icon: 'error',
							timer: 2000,
							buttons: false,
						})
					}
				})

		} catch (error) {
			console.log(error)
		}
	}

	const download = () => {
		let token = localStorage.getItem('token');
		try {
			const searchFromDate = fromDate === '' ? '' : `&start=${start}`
			const searchToDate = toDate === '' ? '' : `&end=${end}`
			const searchStatus = selectStatus === '' ? '' : `&status=${selectStatus}`
			setLoading(true)
			axios.get(Constants.baseUrl + `reports/download/raiseTicket?${searchStatus}${searchFromDate}${searchToDate}`, {

				headers: {
					Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
					appVersion: Constants.av,
					platform: Constants.pt,
					company: Constants.company,
					program: Constants.program,
				},
				responseType: 'blob', // Set the responseType to 'blob'
			})
				.then((res) => {
					if (res.status === 200) {
						setLoading(false)
						const fileData = new Blob([res.data], { type: 'text/csv' });
						saveAs(fileData, 'ticketReport.csv');
					} else {
						swal({
							text: res.data.msg,
							// icon: 'error',
							timer: 2000,
							buttons: false,
						})
					}
				})
		} catch (error) {
			console.log(error)
		}
	}
	const handleSearchQuery = (event) => {
		setSelectStatus(event.target.value)
		// if (event.target.value === '') {
		//     customerCareReport('')
		//     setSearchRecord()
		// }
	}

	return (
		<>
			{loading ? <Oval /> : null}
			<HeaderSales />
			<section class="blog-area pt182">
				<div class="container">
					<div class="row">
						<div class="col-md-12 tab mb-20">
							<ul class="nav nav-tabs" role="tablist">
								<li role="presentation" class="active"><Link to="#Section1" role="tab" data-toggle="tab"> Ticket Report</Link></li>
							</ul>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12 col-sm-12 col-xs-12">
							<div class="all-single-blog rpt">
								<div class="row clearfix mb-15">
									<div class="col-md-3 col-sm-12 col-xs-12">
										<div class="form-group has-float-label style-two">
											<label>From Date<sup>*</sup> </label>
											<DatePicker
												selected={fromDate}
												onChange={(date) => setFromDate(date)}
												dateFormat="dd-MM-yyyy"
												className="form-control"
												id="Fromdate"
												showYearDropdown
												showMonthDropdown
												autoComplete="off"
												scrollableYearDropdown
												maxDate={new Date()}
												onKeyDown={(e) => e.preventDefault()} // Prevent manual input using keyboard
												onFocus={(e) => e.target.blur()} // Blur the input to prevent focus
												onClick={(e) => e.preventDefault()} // Preven
												placeholderText='From date'
											/>
										</div>
									</div>
									<div class="col-md-3 col-sm-12 col-xs-12">
										<div class="form-group has-float-label style-two">
											<label>To Date<sup>*</sup> </label>
											<DatePicker
												selected={toDate}
												onChange={(date) => setToDate(date)}
												dateFormat="dd-MM-yyyy"
												className="form-control"
												id="todate"
												showYearDropdown
												showMonthDropdown
												autoComplete="off"
												scrollableYearDropdown
												maxDate={new Date()}
												onKeyDown={(e) => e.preventDefault()} // Prevent manual input using keyboard
												onFocus={(e) => e.target.blur()} // Blur the input to prevent focus
												onClick={(e) => e.preventDefault()} // Preven
												placeholderText='To date'
											/>
										</div>
									</div>
									<div className="col-md-3 col-sm-12 col-xs-12">
										<div className="form-group has-float-label style-two">
											<label>Status</label>
											<select className="form-control2" value={selectStatus} onChange={handleSearchQuery}>
												<option value="">All</option>
												<option value="OPEN">Open</option>
												<option value="CLOSED">Close</option>
											</select>
										</div>
									</div>
									<div class="col-md-3 col-sm-3 col-xs-12">
										<div class="form-group has-float-label style-two">
											<label class="invisible" style={{ width: "100%" }}>hidden</label>
											<input type="button" class="btn login_btn" value="Search" id="btnSearch" onClick={() => salesTicketReport(selectStatus)} />
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-md-12">
										<div class="carrd1">
											<div class="card-header card-header-warning">
												<h4 class="card-title">Ticket Report
													{searchRecord === null ? '' :
														<Link to="#" class="pull-right" onClick={download}><i class="fa fa-file-excel-o"></i> Export</Link>}
												</h4>
											</div>
											<div class="card-body table-responsive">
												<table class="table table-striped mb_01" id="table-to-xls">
													<thead>
														<tr>
															<th>S.No</th>
															<th>RO Name</th>
															{/* <th>SH Name</th> */}
															<th>Sales Name</th>
															<th>Name</th>
															<th>Mobile Number</th>
															<th>City</th>
															<th>State</th>
															<th>Concern ID</th>
															<th>Date Of Concern</th>
															<th>Concern Type</th>
															<th>Description</th>
															<th>Concern Status</th>
														</tr>
													</thead>
													{searchRecord === null ?
														<tbody>
															<tr>
																<td colSpan="13" style={{ textAlign: 'center', fontSize: '16px' }}>No record found!</td>
															</tr>
														</tbody> :
														<tbody>
															{results.map((item, index) => {
																const serialNumber = (currentPage - 1) * limit + index + 1;
																const inputDate = item.createdDateTime
																const formattedDate = moment(inputDate, 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY');

																return (
																	<>
																		<tr>
																			<td>{serialNumber}</td>
																			<td>{item.user.roName}</td>
																			{/* <td>{item.user.shName}</td> */}
																			<td>{item.user.parentName}</td>
																			<td>{item.user.name}</td>
																			<td>{item.user.mobile}</td>
																			<td>{item.user.addressWork.workCity}</td>
																			<td>{item.user.addressWork.workState}</td>
																			<td>{item.id}</td>
																			<td>{formattedDate}</td>
																			<td>{item.type}</td>
																			<td>{item.description}</td>
																			<td>{item.status}</td>
																		</tr>
																	</>
																)
															})}
														</tbody>}
												</table>
												{totalPages > 1 && (
													<ResponsivePagination
														current={currentPage}
														total={totalPages}
														onPageChange={(page) => setCurrentPage(page)}
													/>
												)}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	)
}
