import React, { useState, useEffect } from 'react'
import Footer from '../common/Footer'
import { Link } from 'react-router-dom'
import HeaderSales from '../common/HeaderSales';
import axios from 'axios';
import * as Constants from "../Constants/index"
import { saveAs } from 'file-saver';
import swal from 'sweetalert';
import ResponsivePagination from 'react-responsive-pagination';
import moment from 'moment';
import Oval from '../../loader/CircleLoade';
import DatePicker from "react-datepicker";
import { useNavigate } from 'react-router-dom';
import { useFormik } from "formik";

export default function SalesQrTransactionReport() {
	const navigate = useNavigate()
	const [searchUser, setSearchUser] = useState('')
	const [searchQRCode, setSearchQRCode] = useState('')
	const [totalPages, setTotalPages] = useState();
	const [currentPage, setCurrentPage] = useState(1);
	const [limit] = useState(10)
	const [errors, setErrors] = useState({});
	const [results, setResults] = useState([])
	const [searchRecord, setSearchRecord] = useState()
	const [loading, setLoading] = useState(false);
	const [fromDate, setFromDate] = useState('');
	const [toDate, setToDate] = useState('');

	const [filterData, setFilterData] = useState({});
	const [downloadLoading, setDownloadLoading] = useState(false);
	let token = localStorage.getItem("token");
	
	useEffect(() => {
    getReportData(
      {
				qrcode: "",
        name: "",
        mobile: "",
        startDate: "",
        endDate: "",
      },
      1
    );
  }, []);

  const getReportData = async (data, pageNum) => {
    setLoading(true);
    if (!token) {
      navigate("/");
    } else {
      // const searchMobile = data.mobile === "" ? "" : `&mobile=${data.mobile}`;
      // const searchStatus = data.status === "" ? "" : `&status=${data.status}`;
      const searchName =
        data?.name !== undefined && data?.name !== ""
          ? `&name=${data?.name}`
          : "";
      const searchMobile =
        data?.mobile !== undefined && data?.mobile !== ""
          ? `&mobile=${data?.mobile}`
          : "";
			
			const searchQrcode =
				data?.qrcode !== undefined && data?.qrcode !== ""
					? `&code=${data?.qrcode}`
					: "";

      let stDate;
      let endDate;

      var momentObj1 = moment(data.startDate);
      var momentObj2 = moment(data.endDate);
      if (data.startDate && data.endDate) {
        var startFormattedDate = momentObj1.format("YYYY-MM-DD HH:mm:ss");
        var startFormattedDate4 = momentObj2.format("YYYY-MM-DD 23:59:59");
        stDate = `&start=${startFormattedDate}`;
        endDate = `&end=${startFormattedDate4}`;
      } else {
        stDate = "";
        endDate = "";
      }

      await axios
        .get(
          Constants.baseUrl +
            `reports/qrTrans/list?userType=MEMBER${searchQrcode}${searchMobile}${stDate}${endDate}&limit=${limit}&page=${pageNum}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Accept-Language": localStorage.getItem("langauge"),
              appVersion: Constants.av,
              platform: Constants.pt,
              company: Constants.company,
              program: Constants.program,
            },
          }
        )
        .then((res) => {
          const dt = res.data;
          if (dt.result === "success") {
            setResults(dt?.output?.results);
            setTotalPages(dt?.output?.totalPages);
            setLoading(false);
          }
          if (dt.result === "error" || dt.result === "dialog") {
            setResults(null);
            setTotalPages(0);
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const download = (data) => {
    setLoading(true);
    setDownloadLoading(true);
    try {
			const searchQrcode = data.qrcode === "" ? "" : `&code=${data.qrcode}`;
      const searchMobile = data.mobile === "" ? "" : `&mobile=${data.mobile}`;
      const searchName = data.name === "" ? "" : `&name=${data.name}`;
      let stDate;
      let endDate;

      var momentObj1 = moment(data.startDate);
      var momentObj2 = moment(data.endDate);
      if (data.startDate && data.endDate) {
        var startFormattedDate = momentObj1.format("YYYY-MM-DD HH:mm:ss");
        var startFormattedDate4 = momentObj2.format("YYYY-MM-DD 23:59:59");
        stDate = `&start=${startFormattedDate}`;
        endDate = `&end=${startFormattedDate4}`;
      } else {
        stDate = "";
        endDate = "";
      }

      axios
        .get(
          Constants.baseUrl +
            `reports/download/qrTransreport?userType=MEMBER${searchQrcode}${searchMobile}${searchName}${stDate}${endDate}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Accept-Language": localStorage.getItem("langauge"),
              appVersion: Constants.av,
              platform: Constants.pt,
              company: Constants.company,
              program: Constants.program,
            },
            responseType: "blob", // Set the responseType to 'blob'
          }
        )
        .then((res) => {
          if (res.status === 200) {
            setLoading(false);
            const fileData = new Blob([res.data], { type: "text/csv" });
            saveAs(fileData, "QR_reports.csv");
            setDownloadLoading(false);
          } else {
            swal({
              text: res.data.msg,
              // icon: 'error',
              timer: 2000,
              buttons: false,
            });
            setDownloadLoading(false);
          }
        });
    } catch (error) {
      console.log(error);
      setDownloadLoading(false);
    }
  };

  const searchFormik = useFormik({
    initialValues: {
			qrcode: "",
      mobile: "",
      name: "",
      startDate: "",
      endDate: "",
    },
    validate: (data) => {
      let errors = {};
      if (data.mobile) {
        if (data.mobile.length < 10) {
          errors.mobile = "Mobile must be in 10 digits.";
        }
      }
      if (data.startDate === "" && data.endDate !== "") {
        errors.startDate = "Please select date";
      }
      if (data.startDate !== "" && data.endDate === "") {
        errors.endDate = "Please select date";
      }
      if (data.startDate && data.endDate && data.startDate > data.endDate) {
        errors.endDate = "Please select date";
      }
      if (data.startDate && data.endDate && data.startDate > data.endDate) {
        errors.endDate = "End date should be greater than start date";
      }
      // if (data.startDate && data.endDate) {
      //     const maxEndDate = new Date(data.startDate)
      //     maxEndDate.setDate(maxEndDate.getDate() + 30) // Adjust the range as needed (e.g., 31 days)

      //     if (data.endDate > maxEndDate) {
      //         errors.endDate = 'End date should be within 31 days from the start date.'
      //     }
      // }
      return errors;
    },
    onSubmit: (data) => {
      setCurrentPage(1);
      getReportData(data, 1);
      setFilterData(data);
    },
  });
  const isSearchFormFieldValid = (name) =>
    !!(searchFormik.touched[name] && searchFormik.errors[name]);
  const getSearchFormErrorMessage = (name) => {
    return (
      isSearchFormFieldValid(name) && (
        <small style={{color:'red'}}>
          {searchFormik.errors[name]}
        </small>
      )
    );
  };

  const resetData = () => {
    setCurrentPage(1);
    setFilterData({});
    searchFormik.resetForm();
    searchFormik.handleReset();
    getReportData(
      {
				qrcode: "",
        name: "",
        mobile: "",
        startDate: "",
        endDate: "",
      },
      1
    );
  };

	return (
		<>
			{loading ? <Oval /> : null}
			<HeaderSales />
			<section class="blog-area pt182">
				<div class="container">
					<div class="row">
						<div class="col-md-12 tab mb-20">
							<ul class="nav nav-tabs" role="tablist">
								<li role="presentation" class="active"><Link to="#Section1" role="tab" data-toggle="tab">QR Transaction  Report</Link></li>
							</ul>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12 col-sm-12 col-xs-12">
							<div class="all-single-blog rpt">
								{/* <div class="row clearfix mb-15">
									<div class="col-md-3 col-sm-12 col-xs-12">
										<div class="form-group has-float-label style-two">
											<label>Mobile No.<sup>*</sup> </label>
											<input className="form-control" placeholder="Mobile no." type="text" maxLength='10' value={searchUser} onChange={handleSearchQuery} onKeyDown={handleKeyDown} />
										</div>
									</div>
									<div class="col-md-3 col-sm-12 col-xs-12">
										<div class="form-group has-float-label style-two">
											<label>QR Code.<sup>*</sup> </label>
											<input className="form-control" placeholder="QR Code" type="text" value={searchQRCode} onChange={handleSearchQRCode} onKeyDown={handleQRKeyDown} />
										</div>
									</div>
									<div class="col-md-3 col-sm-12 col-xs-12">
										<div class="form-group has-float-label style-two">
											<label>From Date<sup>*</sup> </label>
											<DatePicker
												selected={fromDate}
												onChange={(date) => setFromDate(date)}
												dateFormat="dd-MM-yyyy"
												className="form-control"
												id="Fromdate"
												showYearDropdown
												showMonthDropdown
												autoComplete="off"
												scrollableYearDropdown
												maxDate={new Date()}
												onKeyDown={(e) => e.preventDefault()} // Prevent manual input using keyboard
												onFocus={(e) => e.target.blur()} // Blur the input to prevent focus
												onClick={(e) => e.preventDefault()} // Preven
											/>
										</div>
										{errors.fromDate && (
											<div className="error-message">{errors.fromDate}</div>
										)}
									</div>
									<div class="col-md-3 col-sm-12 col-xs-12">
										<div class="form-group has-float-label style-two">
											<label>To Date<sup>*</sup> </label>
											<DatePicker
												selected={toDate}
												onChange={(date) => setToDate(date)}
												dateFormat="dd-MM-yyyy"
												className="form-control"
												id="todate"
												showYearDropdown
												showMonthDropdown
												autoComplete="off"
												scrollableYearDropdown
												maxDate={new Date()}
												onKeyDown={(e) => e.preventDefault()} // Prevent manual input using keyboard
												onFocus={(e) => e.target.blur()} // Blur the input to prevent focus
												onClick={(e) => e.preventDefault()} // Preven
											/>
										</div>
										{errors.toDate && (
											<div className="error-message">{errors.toDate}</div>
										)}
									</div>
									<div class="col-md-12 col-sm-12 col-xs-12 text-center">
										<div class="form-group has-float-label style-two">
											<label class="invisible" style={{ width: "100%" }}>hidden</label>
											<input type="button" class="btn login_btn" value="Search" id="btnSearch" onClick={() => qrTransReport(searchUser, searchQRCode)} />
										</div>
									</div>
								</div> */}

								<form onSubmit={searchFormik.handleSubmit}>
                  <div class="row">
                    <div class="col-lg-9 col-md-12 col-sm-12 col-xs-12">
                      <div class="row">
                        {/* <div className="col-md-3 col-sm-12 col-xs-12">
                          <div class="form-group">
                            <input
                              class="form-control reset"
                              type="text"
                              placeholder="Member Name"
                              id="name"
                              value={searchFormik.values.name}
                              onChange={(e) => {
                                
                                searchFormik.handleChange({
                                  target: {
                                    name: "name",
                                    value: e.target.value,
                                  },
                                });
                              }}
                            />
                            {getSearchFormErrorMessage("mobile")}
                          </div>
                        </div> */}
												<div className="col-md-3 col-sm-12 col-xs-12">
                          <div class="form-group">
                            <input
                              class="form-control reset"
                              type="text"
                              placeholder="QR Code"
                              id="qrcode"
                              value={searchFormik.values.qrcode}
                              onChange={(e) => {
                                const numericValue = e.target.value;
                                searchFormik.handleChange({
                                  target: {
                                    name: "qrcode",
                                    value: numericValue,
                                  },
                                });
                              }}
                            />
                            {getSearchFormErrorMessage("qrcode")}
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-12 col-xs-12">
                          <div class="form-group">
                            <input
                              class="form-control reset"
                              type="text"
                              placeholder="Mobile No."
                              id="mobile"
                              value={searchFormik.values.mobile}
                              onChange={(e) => {
                                const numericValue = e.target.value
                                  .replace(/\D/g, "")
                                  .slice(0, 10);
                                searchFormik.handleChange({
                                  target: {
                                    name: "mobile",
                                    value: numericValue,
                                  },
                                });
                              }}
                            />
                            {getSearchFormErrorMessage("mobile")}
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-12 col-xs-12">
                          <DatePicker
                            id="startDate"
                            selected={searchFormik.values.startDate}
                            onChange={(date) =>
                              searchFormik.setFieldValue("startDate", date)
                            }
                            style={{ zIndex: 999 }}
                            dateFormat="dd-MM-yyyy"
                            className="form-control calnder"
                            showYearDropdown
                            showMonthDropdown
                            autoComplete="off"
                            scrollableYearDropdown
                            maxDate={new Date()}
                            onKeyDown={(e) => e.preventDefault()} // Prevent manual input using keyboard
                            onFocus={(e) => e.target.blur()} // Blur the input to prevent focus
                            onClick={(e) => e.preventDefault()} // Preven
                            placeholderText="From date"
                          />
                          {getSearchFormErrorMessage("startDate")}
                        </div>
                        <div className="col-md-3 col-sm-12 col-xs-12">
                          <DatePicker
                            id="endDate"
                            selected={searchFormik.values.endDate}
                            onChange={(date) =>
                              searchFormik.setFieldValue("endDate", date)
                            }
                            dateFormat="dd-MM-yyyy"
                            className="form-control"
                            showYearDropdown
                            showMonthDropdown
                            autoComplete="off"
                            scrollableYearDropdown
                            maxDate={new Date()}
                            onKeyDown={(e) => e.preventDefault()} // Prevent manual input using keyboard
                            onFocus={(e) => e.target.blur()} // Blur the input to prevent focus
                            onClick={(e) => e.preventDefault()} // Preven
                            placeholderText="To date"
                          />
                          {getSearchFormErrorMessage("endDate")}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                      <div class="d-flex">
                        <button
                          type="submit"
                          class="btn login_btn"
                          //disabled={areAllValuesBlank()}
                        >
                          Search
                        </button>
                        <button
                          type="button"
                          class="btn login_btn"
													style={{ marginLeft: '20px' }}
                          onClick={() => resetData()}
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                  </div>
                </form>


								<div class="row">
									<div class="col-md-12">
										<div class="carrd1">
											<div class="card-header card-header-warning">
												<h4 class="card-title">QR Transaction Report
												<Link
                            to="#"
                            class={`pull-right ${
                              downloadLoading ? "btn-opacity" : ""
                            }`}
                            onClick={() => download(searchFormik.values)}
                          >
                            {downloadLoading ? (
                              <span>
                                {" "}
                                <i class="fa fa-spinner fa-spin"></i>Export
                              </span>
                            ) : (
                              " Export"
                            )}
                          </Link>
												</h4>
											</div>
											<div class="card-body table-responsive">

												<table class="table table-striped mb_01" id="table-to-xls">
													<thead>
														<tr>
															<th>S.no</th>
															<th>Name</th>
															<th>Mobile</th>
															<th>DOJ</th>
															<th>Work State</th>
															<th>Work City	</th>
															<th>Registred From</th>
															<th>Parent Name</th>
															<th>Points</th>
															<th>Category</th>
															<th>Sub Category</th>
															<th>SAP Code	</th>
															<th>Transaction Time</th>
															<th>Code</th>
															<th>Status</th>
															<th>Reason</th>
															<th>ID</th>
															<th>Points Earned</th>
														</tr>
													</thead>
													{searchRecord === null ?
														<tbody>
															<tr>
																<td colSpan="11" style={{ textAlign: 'center', fontSize: '16px' }}>No record found!</td>
															</tr>
														</tbody> :
														<tbody>
															{results.map((item, index) => {
																const serialNumber = (currentPage - 1) * limit + index + 1;
																const scanDate = moment(item.scanDateTime).format('DD-MM-YYYY')
																const doj = moment(item.user.createDateTime, 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY')
																// const transTime = moment(item.createDateTime).format('DD-MM-YYYY')
																return (
																	<>
																		<tr>
																			<td>{serialNumber}</td>
																			<td>{item.user.name}</td>
																			<td>{item.user.mobile}</td>
																			<td>{doj}</td>
																			<td>{item.user.addressWork?.workState}</td>
																			<td>{item.user.addressWork?.workCity}</td>
																			<td>{item.user?.registerFrom}</td>
																			<td>{item.user?.parentName}</td>
																			<td>{item.user?.points}</td>
																			
																			<td>{item.programProduct?.category}</td>
																			<td>{item.programProduct?.subCategory}</td>
																			<td>{item.programProduct?.sapCode}</td>
																			<td>{scanDate}</td>
																			<td>{item.code}</td>
																			<td>{item.status}</td>
																			<td>{item.reason}</td>
																			{/* <td>{item.manufacturingDate}</td> */}
																			{/* <td>{item.delearNumber}</td> */}
																			{/* <td>{item.delearName}</td> */}
																			{/* <td>{item.createDateTime}</td>
																			<td>{item.pointsEarned}</td>
																			
																			<td>{item.user?.totalPoints}</td> */}
																			

																			
																			<td>{item.id}</td>
																			<td>{item.pointsEarned}</td>
																		</tr>
																	</>
																)
															})}

														</tbody>}
												</table>
												{totalPages > 1 && (
                          <ResponsivePagination
                            current={currentPage}
                            total={totalPages}
                            onPageChange={(page) => {
                              setCurrentPage(page);
                              getReportData(filterData, page);
                            }}
                          />
                        )}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	)
}
