import React from 'react'

export default function Terms_Conditions() {
    return (
        <>
            <div className="terms">

                <h3>Terms and Conditions:</h3>
                <ul className='tnc'>
                    <li>Registration of a mason in the program is voluntary.</li>
                    <li>Products (covered under this Program) purchased from Authorized Dealers and retailers of CERA-India will be considered for this Program</li>
                    <li>This Program cannot be clubbed with any other scheme/ program/offer unless approved in writing by CERA.</li>
                    <li>Only masons are eligible for registration in the CERA Mason loyalty program, subject to validation from the CERA sales team. </li>
                    <li>Registering/registered member must not be CERA's employee, oﬃcer, or agent, or an employee, oﬃcer, or agent of any person or organization involved in the running of the program, and must not be a family relation of any such person.</li>
                    <li>Any benefit or reward that comes under THE LOTTERIES (REGULATION) ACT, 1998 will not be applicable for the members registered and operating in the state of Tamil Nadu The value of 1 star will be INR 1/-</li>
                    <li>Accrued Stars will be redeemed against Paytm wallet, UPI, Bank transfer products, and e-vouchers. Physical products available in the reward catalog until any further condition is applied by CERA.</li>
                    <li>No loyalty reward Stars shall be awarded on any duplicate scanning.</li>
                    <li>Neither this program nor its terms and conditions shall be construed as a joint venture, agency relationship, or as granting of a franchisee.</li>
                    <li>CERA reserves the right to amend or withdraw the “CERA Mason” at any point in time without giving any reason(s) and intimation(s) for the same.</li>
                    <li>CERA reserves the right to revoke the benefits under this Program in the event of the nonproduction of any of the necessary documents and/or submission of the false, incorrect, misleading, or fraudulent document(s)/ information furnished by enrolling/registered members.</li>
                    <li>The registered member is liable for disqualification/ expulsion from the program and other such programs/ incentive schemes, on the observation/report of any unfair trade practices being carried out in the program or otherwise and his/her reward amount can also be forfeited which shall be final, conclusive, and binding and will not be liable to be disputed or questioned.</li>
                    <li>CERA will not be liable for any loss or damage, whether direct or indirect, caused to registered members due to withdrawal or change in the scheme.</li>
                    <li>All registered members will be rewarded as per the pre-defined awarding matrix set by CERA.</li>
                    <li>The Stars or any other benefits under the present Program are non-transferable.</li>
                    <li>All registered members irrevocably acknowledge that all the terms and conditions have been fully read and understood by them.</li>
                    <li>CERA does not take any type of responsibility such as quality, after-sales service, warranty, performance, etc. on the third-party goods provided in the gift/catalog list.</li>
                    <li>CERA shall not be liable for any technical, or physical delay in transmission or submission of the entries by the members or any disruptions, losses, damages, computer-related malfunctions/ failures which affect the participation of the members, or any force majeure conditions or damages caused by Act of God, Governmental actions/restrictions/lockdown, pandemic/epidemic.</li>
                    <li>CERA shall also not be responsible for the non-availability of Stock(s) due to various reasons like climatic conditions, labour unrest, insolvency, business exigencies, or operational & technical issues.</li>
                    <li>Any disputes, controversies, and conflicts arising about the usage or non-usage of Stars shall communicate to CERA. CERA will then appoint/authorize the committee to resolve the dispute at the earliest. The present program runs to encourage masons by way of sharing rewards and bringing transparency to the business transaction. It is not CERA's legal liability for any compulsory obligation towards masons. Therefore, Masons shall not claim it as their right or legal due. CERA holds and reserves all discretionary rights to change, modify and alter the rewards system without any prior notice to Masons.</li>
                    <li>Disputes, if any are subject to Ahmedabad (Gujarat) Jurisdiction.</li>
                </ul>
            </div>
        </>
    )
}
