import React, { useState, useEffect } from 'react'
import logo_2 from '../assets/img/logo/logo2.png'
import about_cera from '../assets/img/aboutcera.png'
import home from '../assets/img/home.png'
import cera_icon from '../assets/img/ceraicon.png'
import about from '../assets/img/about.png'
import cera_look_book from '../assets/img/cera_look_book.pdf'
import lookbook from '../assets/img/lookbook.png'
import contact from '../assets/img/contact.png'
import { Link } from 'react-router-dom'
import axios from 'axios';
import * as Constants from "./Constants/index"
import swal from 'sweetalert';
import bgvdo from '../assets/img/bgvdo.mp4'
import { useNavigate } from 'react-router-dom'

export default function Login() {
    const [year] = useState(new Date().getFullYear())
    const [mobile, setMobile] = useState('')
    const [password, setPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [errors, setErrors] = useState({});
    const [activeTab, setActiveTab] = useState('1a');
    // const [loading, setLoading] = useState(false)
    const navigate = useNavigate();

    const toggleShowPassword = () => {
        if (showPassword === false) {
            setShowPassword(true)
        } else {
            setShowPassword(false)
        }
    }

    const handleClick = (tabId) => {
        setActiveTab(tabId);
    };

    useEffect(() => {
        const userId = localStorage.getItem('user_id');
        if (userId) {
            // swal('You are already logged in!');
            // window.location = '/sales_dashboard'
            navigate('/sales_dashboard')
        }
    }, [])

    const validateForm = () => {
        let errors = {};

        if (!mobile) {
            errors.mobile = 'User ID is required';
        } else if (!/^\d+$/.test(mobile)) {
            errors.mobile = 'User ID should be numeric';
        } else if (mobile.length !== 10) {
            errors.mobile = 'User ID should be 10 digits';
        }

        if (!password) {
            errors.password = 'Password is required';
        }

        return errors;
    };

    const handleLogin = async () => {
        setErrors({});

        const validationErrors = validateForm();

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return; // Don't proceed with the API call if there are validation errors
        }
        try {
            await axios.post(Constants.baseUrl + 'auth/login-password',
                {
                    mobile: mobile,
                    password: password,
                },
                {
                    headers: {
                        'Accept-Language': localStorage.getItem('language'),
                        appVersion: Constants.av,
                        platform: Constants.pt,
                        company: Constants.company,
                        program: Constants.program,
                    },
                }
            )
                .then((res) => {
                    if (res.status === 200) {
                        if (res.data.code === 10001) {
                            if ((res.data.output.user.userType === 'HO') || (res.data.output.user.userType === 'RO') || (res.data.output.user.userType === 'SH') || (res.data.output.user.userType === 'SALES') || (res.data.output.user.userType === 'MK')) {
                                localStorage.setItem('user_id', res.data.output.user.id);
                                localStorage.setItem('user_type', res.data.output.user.userType);
                                localStorage.setItem('user_name', res.data.output.user.name);
                                localStorage.setItem('user_email', res.data.output.user.email);
                                localStorage.setItem('user_mobile', res.data.output.user.mobile);
                                localStorage.setItem('token', res.data.output.access.token);
                                navigate('/sales_dashboard')
                            } else {
                                swal({
                                    text: 'You are not a Sales Team Member',
                                    icon: 'error',
                                    buttons: false,
                                    timer: 2000
                                });
                            }

                        } else if (res.data.code === 16001) {
                            swal({
                                text: res.data.msg,
                                buttons: false,
                                timer: 2000,
                            });
                        } else {
                            swal({
                                text: res.data.msg,
                                buttons: false,
                                timer: 2000,
                            });
                        }
                    } else {
                        swal({
                            text: res.data.msg,
                            buttons: false,
                            timer: 2000,
                        });
                    }
                });

        } catch (error) {
            console.log(error);
        }
    }
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleLogin();
        }
    };


    return (
        <>
            {/* <Circles
                height="80"
                width="80"
                color="#4fa94d"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
            /> */}
            <video autoPlay muted loop className="bg_video vdofix">
                <source src={bgvdo} type="video/mp4" />
                <source src={bgvdo} type="video/webm" />
                your browser is not supported
            </video>
            <section className="loginsec">
                <div className="container">
                    <div className="loginwindow">
                        <div className="row mr0">
                            <div className="col-md-4 loginside">
                                <div className="formlogin">
                                    <figure className="logomob">
                                        <img src={logo_2} alt='' />
                                    </figure>
                                    <h2>Log In</h2>
                                    <div className="form-group">
                                        <label htmlFor="user id">User ID</label>
                                        <input type="text" value={mobile} maxLength="10" onChange={(event) => {
                                            const input = event.target.value;
                                            if (/^\d*$/.test(input)) {
                                                setMobile(input);
                                            }
                                        }} placeholder="User ID" className="form-control frm" onKeyDown={handleKeyDown} />
                                    </div>
                                    {errors.mobile && (
                                        <div className="error-message-login">{errors.mobile}</div>
                                    )}

                                    <div className="form-group">
                                        <label htmlFor="review">Password</label>
                                        <span className="showpass" title="Show Password" onClick={toggleShowPassword}>
                                            {showPassword === false ? <i className="fa fa-eye"></i> : <i className="fa fa-eye-slash"></i>}</span>
                                        <input type={showPassword === true ? "text" : "password"} id="myInput" value={password} onChange={(event) => setPassword(event.target.value)} placeholder="Enter Password" className="form-control frm" onKeyDown={handleKeyDown} />
                                    </div>
                                    {errors.password && (
                                        <div className="error-message-login">{errors.password}</div>
                                    )}

                                    <div className="form-group mtop20">
                                        <input type="submit" value="LOGIN" onClick={handleLogin} className="btn loginbuttn" />
                                    </div>
                                    <div className="loggedin">
                                        <label><input type="checkbox" /> Keep me logged in</label>
                                    </div>
                                    <div className="loginwithotp">
                                        <Link to="/login_with_otp">Login with OTP</Link>
                                    </div>
                                    <div className="forgtPassword">
                                        <Link to="/forgot_password">Forgot password ?</Link>
                                    </div>

                                    <div className="termsConditions">
                                        <Link to="/app/tnc">Terms & conditions</Link>
                                    </div>
                                    <div className="copyrighttext text-center hidden-xs">
                                        <p>Copyright © {year} <Link to="http://evolvebrands.com/" target="_blank">Evolve Brands Pvt Ltd</Link>, <br /> All Rights Reserved.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-8 logoside">
                                <figure className="abtright hidden-xs">
                                    <img src={about_cera} alt="" />
                                    {/* <!-- <p>
                                                CERA StarMason is a loyalty program that brings reward benefits to the associated partners (Masons) on every purchase made from CERA authorized dealers and retailers. The purpose of this program is to strengthen the relationship with the Masons and provide transparent communication
                                            </p> --> */}
                                </figure>

                                {/* <!-- <div className="bottom-nav">
                                        <ul className="bottom-menu">
                                            <li><Link to="index.html"> <img src="img/home.png"> Home</Link></li>
                                            <li><Link to="about_cera.html"> <img src="img/ceraicon.png">  About Cera</Link></li>
                                            <li><Link to="about_cera_star_mason.html"><img src="img/about.png"> About Cera StarMason</Link></li>
                                            <li><Link to="img/cera_look_book.pdf" target="_blank"><img src="img/lookbook.png"> Cera Look Book</Link></li>
                                            <li><Link to="contact.html"> <img src="img/contact.png"> Contact Us</Link></li>
                                        </ul>
                                    </div> --> */}
                                <div id="tabbing">
                                    <div className="tab-content clearfix">
                                        <div className={`tab-pane ${activeTab === '1a' ? 'active' : ''}`} id="1a">
                                            <p>
                                                CERA StarMason is a loyalty program that brings reward benefits to the associated  Masons on every purchase made from CERA authorized dealers and retailers. <br /> The purpose of this program is to strengthen the relationship with the Masons and provide transparent communication.
                                            </p>
                                        </div>
                                        <div className={`tab-pane ${activeTab === '2a' ? 'active' : ''}`} id="2a">
                                            <div className="aboutceraindia" id="scrollabt">
                                                <p className="innovate">Style. Innovation. Leadership.</p>
                                                <p>
                                                    These three words capture the essence of CERA Sanitaryware Limited, One of India's fastest-growing company in the segment. Keeping the needs of the modern customer in mind,we have opened the doorway to the world of style.
                                                </p>
                                                <p>
                                                    Our constant innovations have provided several path- breaking contributions to the industry-like water- saving twin-flush coupled WCs, 4-litre flush WCs, one- piece  WCs, etc.
                                                </p>
                                                <p>
                                                    Advanced technology has been the forte of CERA. Its state-of-the-art manufacturing plant has been following the highest standards of quality with an emphasis on sustainability since its inception in 1980. The production capacity of sanitaryware has been elevated from 2.7 million pieces to 3 million pieces per annum. We always plan to maintain our leadership status in the industry while catering to increased demands and effortless supply across the nation.
                                                </p>
                                                <p>
                                                    With CERA, style blends with innovation to reveal a new dimension of modernity.

                                                </p>

                                            </div>
                                        </div>
                                        <div className={`tab-pane ${activeTab === '3a' ? 'active' : ''}`} id="3a">
                                            <p>
                                                CERA StarMason is a loyalty program that brings reward benefits to the associated  Masons on every purchase made from CERA authorized dealers and retailers. <br /> The purpose of this program is to strengthen the relationship with the Masons and provide transparent communication.
                                            </p>
                                        </div>
                                        <div className={`tab-pane ${activeTab === '4a' ? 'active' : ''}`} id="4a">
                                            <div className="row">
                                                <div className="col-md-4 col-xs-12">
                                                    <h4 className="column-title ctl">Address</h4>
                                                    <address>
                                                        C/o Evolve Brands Pvt Ltd
                                                        <br />
                                                        249 G, AIHP Tower,

                                                        Level 2,<br /> Wing A,
                                                        Udyog Vihar Phase 4,
                                                        Gurugram,122015


                                                    </address>
                                                </div>
                                                <div className="col-md-4 col-xs-12">
                                                    <h4 className="column-title ctl">May I help you</h4>
                                                    {/* <!--<i className="fa fa-phone" aria-hidden="true"></i> : 011-39588398--> */}
                                                    <i className="fa fa-phone" aria-hidden="true"></i> : 18002026182
                                                    <br />
                                                    <i className="fa fa-envelope" aria-hidden="true"></i> : info@cerastarmason.com

                                                </div>
                                                <div className="col-md-4 col-xs-12">
                                                    <h4 className="column-title ctl">Our Timings</h4>
                                                    (Monday to Friday)<br />(09:30am to 5:30pm)
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <ul className="bottom-menu">
                                        <li className={activeTab === '1a' ? 'active' : ''}>
                                            <Link to="" onClick={() => handleClick('1a')} data-toggle="tab" aria-expanded={activeTab === '1a'}>
                                                <img src={home} alt="" /> Home
                                            </Link>
                                        </li>
                                        <li className={activeTab === '2a' ? 'active' : ''}>
                                            <Link to="" onClick={() => handleClick('2a')} data-toggle="tab" aria-expanded={activeTab === '2a'}>
                                                <img src={cera_icon} alt="" /> About Cera
                                            </Link>
                                        </li>
                                        <li className={activeTab === '3a' ? 'active' : ''}>
                                            <Link to="" onClick={() => handleClick('3a')} data-toggle="tab" aria-expanded={activeTab === '3a'}>
                                                <img src={about} alt="" /> About Cera StarMason
                                            </Link>
                                        </li>
                                        <li>
                                            <a href={cera_look_book} target="_blank">
                                                <img src={lookbook} alt="" /> Cera Look Book
                                            </a>
                                        </li>
                                        <li className={activeTab === '4a' ? 'active' : ''}>
                                            <Link to="" onClick={() => handleClick('4a')} data-toggle="tab" aria-expanded={activeTab === '4a'}>
                                                <img src={contact} alt="" /> Contact Us
                                            </Link>
                                        </li>
                                    </ul>

                                    {/* <div className="bottom-nav">
                                        <ul className="bottom-menu">
                                            <li className="active"><Link to="#1a" data-toggle="tab" aria-expanded="true"> <img src={home} alt="" /> Home</Link></li>
                                            <li><Link to="#2a" data-toggle="tab" aria-expanded="false"> <img src={cera_icon} alt="" />  About Cera</Link></li>
                                            <li><Link to="#3a" data-toggle="tab" aria-expanded="false"><img src={about} alt="" /> About Cera StarMason</Link></li>
                                            <li><Link to={cera_look_book} target="_blank"><img src={lookbook} alt="" /> Cera Look Book</Link></li>
                                            <li><Link to="#4a" data-toggle="tab" aria-expanded="false"> <img src={contact} alt="" /> Contact Us</Link></li>
                                        </ul>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
