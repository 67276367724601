import React, { useState, useEffect } from "react";
import HeaderSales from "./common/HeaderSales";
import Footer from "./common/Footer";
import { Link } from "react-router-dom";
import axios from "axios";
import * as Constants from "./Constants/index";
import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/minimal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import swal from "sweetalert";
import { saveAs } from "file-saver";
import Oval from "../loader/CircleLoade";
import moment from "moment";

export default function ApprovedMason() {
  const [results, setResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [isVisible, setIsVisible] = useState(false);
  const [limit] = useState("10");
  const [registartionShow, setRegistartionShow] = useState(false);
  const [bankDetailShow, setBankDetailShow] = useState("");
  const [upiDetailsShow, setUpiDetailsShow] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);

  const userType = localStorage.getItem("user_type");

  useEffect(() => {
    if (searchQuery === "") {
      // Call the function when the searchQuery becomes empty
      approvedRegistrationData();
      setSearchRecord();
    }
  }, [currentPage]);
  const [errors, setErrors] = useState({});
  const [searchRecord, setSearchRecord] = useState();

  // registraion state
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  // const [aadhar, setAadhar] = useState('')
  // const [mobileAadhar, setMobileAadhar] = useState('')
  // const [aadharFront, setAadharFront] = useState('');
  // const [aadharBack, setAadharBack] = useState();
  const [email, setEmail] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [address3, setAddress3] = useState("");
  const [state, setState] = useState("");
  // const [district, setDistrict] = useState('')
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState("");

  //bank details
  const [accountNo, setAccountNo] = useState("");
  const [accountType, setAccountType] = useState("");
  const [ifsc, setIfsc] = useState("");
  const [bankName, setBankName] = useState("");
  const [holderName, setHolderName] = useState("");
  const [cancelChk, setCancelChk] = useState("");
  const [upiId, setUpiId] = useState("");

  // id details
  const [idName, setIdName] = useState("");
  const [idNumber, setIdNumber] = useState("");
  const [idFrontImage, setIdFrontImage] = useState("");
  const [idBackImage, setIdBackImage] = useState("");
  const [panNumber, setPanNumber] = useState("");
  const [panFrontUrl, setPanFrontUrl] = useState("");

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    approvedRegistrationData();
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [currentPage, bankDetailShow, upiDetailsShow]);

  let token = localStorage.getItem("token");
  const approvedRegistrationData = async () => {
    try {
      setLoading(true);
      await axios
        .get(
          Constants.baseUrl +
            `user/manage/userByparentId?name=${searchQuery}&userType=MEMBER&status=APPROVED&sortBy=createDateTime&sortAsc=false&limit=${limit}&page=${currentPage}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Accept-Language": localStorage.getItem("langauge"),
              appVersion: Constants.av,
              platform: Constants.pt,
              company: Constants.company,
              program: Constants.program,
            },
          }
        )
        .then((res) => {
          if (res.status === 201) {
            if (res.data.code === 10001) {
              setLoading(false);
              setResults(res.data.output.results);
              setTotalPages(res.data.output.totalPages);
            } else if (res.data.code === 12002) {
              setLoading(false);
              setSearchRecord(res.data.output.results);
            } else {
              swal({
                text: res.data.msg,
                icon: "error",
                timer: 2000,
                buttons: false,
              });
            }
          } else {
            swal({
              text: res.data.msg,
              icon: "error",
              timer: 2000,
              buttons: false,
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  // view registraion form
  const handleShowReg = async (id) => {
    let token = localStorage.getItem("token");
    try {
      await axios
        .get(Constants.baseUrl + `user/manage/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": localStorage.getItem("langauge"),
            appVersion: Constants.av,
            platform: Constants.pt,
            company: Constants.company,
            program: Constants.program,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            const data = res.data.output;
            // setIdData(data);
            // Set values for basic details
            setName(data.name);
            setMobile(data.mobile);
            setEmail(data.email);
            // Set values for user model KYC state
            // setMobileAadhar(data.userModelKYC.aadharMobile)
            // setAadhar(data.userModelKYC.aadharNumber)
            // setAadharFront(data.userModelKYC.aadharFrontUrl);
            // setAadharBack(data.userModelKYC.aadharBackUrl);

            // Set values for address work
            setAddress1(data.addressWork.workAddress1);
            setAddress2(data.addressWork.workAddress2);
            setAddress3(data.addressWork.workAddress3);
            setState(data.addressWork.workState);
            // setDistrict(data.addressWork.workDistrict);
            setCity(data.addressWork.workCity);
            setPincode(data.addressWork.workPincode);

            setIdName(res.data.output.identityType);
            setIdNumber(res.data.output.identityNumber);
            setIdFrontImage(res.data.output.identityCardFrontUrl);
            setIdBackImage(res.data.output.identityCardBackUrl);
            setPanNumber(res.data.output.userModelKYC.panNumber);
            setPanFrontUrl(res.data.output.userModelKYC.panFrontUrl);

            // bank details
            if (data.bankDetail === null) {
              setRegistartionShow(true);
            }
            setAccountNo(data.bankDetail.bankAccountNo);
            setAccountType(data.bankDetail.accountType);
            setIfsc(data.bankDetail.ifsc);
            setBankName(data.bankDetail.bankName);
            setHolderName(data.bankDetail.holderName);
            setCancelChk(data.bankDetail.cancelChk);
            setUpiId(data.bankDetail.upiId);

            if (
              data.bankDetail.bankAccountNo !== "" &&
              data.bankDetail.upiId !== ""
            ) {
              setBankDetailShow("bankdetails");
              setUpiDetailsShow("upidetails");
            }
            if (data.bankDetail.bankAccountNo !== "") {
              setBankDetailShow("bankdetails");
            } else {
              setBankDetailShow("");
            }
            if (data.bankDetail.upiId !== "") {
              setUpiDetailsShow("upidetails");
            } else {
              setUpiDetailsShow("");
            }
            setRegistartionShow(true);
            window.scrollTo({
              top: 600,
              behavior: "smooth",
            });
          } else {
            swal(res.data.msg);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
  const validationSearch = () => {
    let errors = {};
    // if (!searchQuery) {
    //   errors.searchQuery = "Name / Mobile no. is required";
    // }
    return errors;
  };

  const handleSearch = () => {
    setCurrentPage(1)
    let token = localStorage.getItem("token");
    setErrors({});
    const validationErrors = validationSearch();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return; // Don't proceed with the API call if there are validation errors
    }
    const searchData = async () => {
      setResults([]);
      try {
        setLoading(true);
        await axios
          .get(
            Constants.baseUrl +
              `user/manage/userByparentId?name=${searchQuery}&userType=MEMBER&status=APPROVED&sortBy=createDateTime&sortAsc=false&limit=${limit}&page=${currentPage}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Accept-Language": localStorage.getItem("langauge"),
                appVersion: Constants.av,
                platform: Constants.pt,
                company: Constants.company,
                program: Constants.program,
              },
            }
          )
          .then((res) => {
            if (res.status === 201) {
              if (res.data.code === 10001) {
                setLoading(false);
                setResults(res.data.output.results);
                setTotalPages(res.data.output.totalPages);
                setSearchRecord();
              } else if (res.data.code === 12002) {
                setLoading(false);
                setSearchRecord(res.data.output.results);
                setTotalPages(res.data.output.totalPages);
              } else {
                swal({
                  text: res.data.msg,
                  icon: "error",
                  timer: 2000,
                  buttons: false,
                });
              }
            } else {
              swal({
                text: res.data.msg,
                icon: "error",
                timer: 2000,
                buttons: false,
              });
            }
          });
      } catch (error) {
        console.log(error);
      }
    };
    searchData();
  };
  const download = () => {
    let token = localStorage.getItem("token");
    try {
      setLoading(true);
      axios
        .post(
          Constants.baseUrl +
            `user/csv/download/usermanage?name=${searchQuery}&userType=MEMBER&status=APPROVED`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Accept-Language": localStorage.getItem("langauge"),
              appVersion: Constants.av,
              platform: Constants.pt,
              company: Constants.company,
              program: Constants.program,
            },
            responseType: "blob", // Set the responseType to 'blob'
          }
        )
        .then((res) => {
          if (res.status === 200) {
            setLoading(false);
            const fileData = new Blob([res.data], { type: "text/csv" });
            saveAs(fileData, "approved.csv");
          } else {
            swal({
              text: res.data.msg,
              // icon: 'error',
              timer: 2000,
              buttons: false,
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleSearchQuery = (event) => {
    setSearchQuery(event.target.value);
    // if (event.target.value === '') {
    //     approvedRegistrationData()
    // }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleScroll = () => {
    const scrollTop = window.pageYOffset;

    if (scrollTop > 200) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // const handlePageChange = (newPage) => {
  //     setCurrentPage(newPage);
  // }

  return (
    <>
      {loading ? <Oval /> : null}
      <HeaderSales />
      <section className="blog-area pt182">
        <div className="container">
          <div className="row">
            <div className="col-md-12 tab mb-20">
              <ul className="nav nav-tabs" role="tablist">
                <li role="presentation" className="active">
                  <Link to="#Section1" role="tab" data-toggle="tab">
                    Approved Masons{" "}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="all-single-blog rpt">
                <div className="row clearfix mb-15">
                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <div className="form-group has-float-label style-two">
                      <label>Name / Mobile No </label>
                      <input
                        className="form-control"
                        placeholder=""
                        type="text"
                        value={searchQuery}
                        onChange={handleSearchQuery}
                        onKeyDown={handleKeyDown}
                      />
                    </div>
                    {errors.searchQuery && (
                      <div className="error-message">{errors.searchQuery}</div>
                    )}
                  </div>
                  <div className="col-md-3 col-sm-3 col-xs-12">
                    <div className="form-group has-float-label style-two">
                      <label className="invisible" style={{ width: "100%" }}>
                        hidden
                      </label>
                      <input
                        type="button"
                        className="btn login_btn"
                        value="Search"
                        id="btnSearch"
                        onClick={handleSearch}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="carrd1">
                      <div className="card-header card-header-warning">
                        <h4 className="card-title">
                          {" "}
                          Approved Masons
                          {searchRecord === null ? (
                            ""
                          ) : (
                            <Link
                              to="#"
                              className="pull-right"
                              onClick={download}
                            >
                              <i className="fa fa-file-excel-o"></i> Export
                            </Link>
                          )}
                        </h4>
                      </div>
                      <div className="card-body table-responsive">
                        <table
                          className="table table-striped mb_01"
                          id="table-to-xls"
                        >
                          <thead>
                            <tr>
                              <th>S.No.</th>
                              <th>View User</th>
                              {userType === "SH" ||
                              userType === "RO" ||
                              userType === "HO" ? (
                                <th>Approved By</th>
                              ) : (
                                ""
                              )}
                              <th>Unique ID</th>
                              <th>User ID</th>
                              <th>User Name</th>
                              <th>City</th>
                              <th>District</th>
                              <th>State</th>
                              <th>Date of Registration</th>
                            </tr>
                          </thead>
                          {searchRecord === null ? (
                            <tbody>
                              <tr>
                                <td
                                  colSpan="9"
                                  style={{
                                    textAlign: "center",
                                    fontSize: "16px",
                                  }}
                                >
                                  No record found!
                                </td>
                              </tr>
                            </tbody>
                          ) : (
                            <tbody>
                              {results.map((item, index) => {
                                const serialNumber =
                                  (currentPage - 1) * limit + index + 1;
                                const approvedBy = item.approval
                                  ? item.approval.actionBy.name
                                  : null;
                                // const dateOfRegistration = item.createDateTime ? moment(item.createDateTime).format('DD-MM-YYYY') : ''
                                const formattedDate = moment(
                                  item.createDateTime,
                                  "DD-MM-YYYY HH:mm"
                                ).format("DD-MM-YYYY");

                                return (
                                  <>
                                    <tr key={item.id}>
                                      <td>{serialNumber}</td>
                                      <td
                                        className="viewUserDetails"
                                        onClick={() => handleShowReg(item.id)}
                                      >
                                        View
                                      </td>
                                      {userType === "SH" ||
                                      userType === "RO" ||
                                      userType === "HO" ? (
                                        <td>{item.approvedBy} </td>
                                      ) : (
                                        ""
                                      )}
                                      <td>{item.id}</td>
                                      <td>{item.mobile}</td>
                                      <td>{item.name}</td>
                                      <td>{item.addressWork.workCity}</td>
                                      <td>{item.addressWork.workdistrict}</td>
                                      <td>{item.addressWork.workState}</td>
                                      <td>{formattedDate}</td>
                                    </tr>
                                  </>
                                );
                              })}
                            </tbody>
                          )}
                        </table>
                        {totalPages > 1 && (
                          <ResponsivePagination
                            current={currentPage}
                            total={totalPages}
                            onPageChange={(page) => setCurrentPage(page)}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {registartionShow === true ? (
        <section className="blog-area">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="all-single-blog rpt">
                  <div className="row">
                    <div className="col-md-3 col-sm-12 col-xs-12">
                      <div className="form-group has-float-label style-two">
                        <label>Name* </label>
                        <input
                          className="form-control"
                          type="text"
                          value={name}
                          disabled
                        />
                      </div>
                    </div>

                    <div className="col-md-3 col-sm-12 col-xs-12">
                      <div className="form-group has-float-label style-two">
                        <label>Mobile No.* </label>
                        <input
                          className="form-control"
                          maxLength="10"
                          type="text"
                          value={mobile}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-12 col-xs-12">
                      <div className="form-group has-float-label style-two">
                        <label>ID Type.* </label>
                        <input
                          className="form-control"
                          maxLength="10"
                          type="text"
                          value={idName}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-12 col-xs-12">
                      <div className="form-group has-float-label style-two">
                        <label>ID No.* </label>
                        <input
                          className="form-control"
                          maxLength="10"
                          type="text"
                          value={idNumber}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-12 col-xs-12">
                      <div className="form-group has-float-label style-two">
                        <label>ID Front Image </label>
                        <Link to={idFrontImage} target="_blank">
                          <span>
                            <i class="fa fa-eye">View</i>
                          </span>
                        </Link>
                        <input
                          className="form-control"
                          id=""
                          name=""
                          type="file"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-12 col-xs-12">
                      <div className="form-group has-float-label style-two">
                        <label>ID Back Image </label>
                        <Link to={idBackImage} target="_blank">
                          <span>
                            <i class="fa fa-eye">View</i>
                          </span>
                        </Link>
                        <input
                          className="form-control"
                          id=""
                          name=""
                          type="file"
                          disabled
                        />
                      </div>
                    </div>
                    {panNumber !== "" ? (
                      <div className="col-md-3 col-sm-12 col-xs-12">
                        <div className="form-group has-float-label style-two">
                          <label>PAN NO.* </label>
                          <input
                            className="form-control"
                            maxLength="10"
                            type="text"
                            value={panNumber}
                            disabled
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {panNumber !== "" ? (
                      <div className="col-md-3 col-sm-12 col-xs-12">
                        <div className="form-group has-float-label style-two">
                          <label>PAN Card Image </label>
                          <Link to={panFrontUrl} target="_blank">
                            <span>
                              <i class="fa fa-eye">View</i>
                            </span>
                          </Link>
                          <input
                            className="form-control"
                            id=""
                            name=""
                            type="file"
                            disabled
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="col-md-3 col-sm-12 col-xs-12">
                      <div className="form-group has-float-label style-two">
                        <label>Email ID </label>
                        <input
                          className="form-control"
                          type="email"
                          value={email}
                          disabled
                        />
                      </div>
                    </div>
                    {/* <div className="col-md-3 col-sm-12 col-xs-12">
										 <div className="form-group has-float-label style-two">
											 <label>Password*  </label>
											 <input className="form-control" placeholder="Enter Password" type="text" value={password} />
										 </div>
									 </div> */}
                    <div className="col-md-3 col-sm-12 col-xs-12">
                      <div className="form-group has-float-label style-two">
                        <label>Address 1*</label>
                        <input
                          className="form-control"
                          type="text"
                          value={address1}
                          disabled
                        />
                      </div>
                    </div>

                    <div className="col-md-3 col-sm-12 col-xs-12">
                      <div className="form-group has-float-label style-two">
                        <label>Addsress 2*</label>
                        <input
                          className="form-control"
                          type="text"
                          value={address2}
                          disabled
                        />
                      </div>
                    </div>

                    <div className="col-md-3 col-sm-12 col-xs-12">
                      <div className="form-group has-float-label style-two">
                        <label>Address 3</label>
                        <input
                          className="form-control"
                          type="text"
                          value={address3}
                          disabled
                        />
                      </div>
                    </div>

                    <div className="col-md-3 col-sm-12 col-xs-12">
                      <div className="form-group has-float-label style-two">
                        <label> State*</label>
                        <input
                          className="form-control"
                          type="text"
                          value={state}
                          disabled
                        />
                      </div>
                    </div>

                    {/* <div className="col-md-3 col-sm-12 col-xs-12">
										 <div className="form-group has-float-label style-two">
											 <label>	District*</label>
											 <input className="form-control" type="text" value={district} disabled />
										 </div>
									 </div> */}

                    <div className="col-md-3 col-sm-12 col-xs-12">
                      <div className="form-group has-float-label style-two">
                        <label> City*</label>
                        <input
                          className="form-control"
                          type="text"
                          value={city}
                          disabled
                        />
                      </div>
                    </div>

                    <div className="col-md-3 col-sm-12 col-xs-12">
                      <div className="form-group has-float-label style-two">
                        <label> Pin Code*</label>
                        <input
                          className="form-control"
                          type="text"
                          value={pincode}
                          onChange={(event) => setPincode(event.target.value)}
                          disabled
                        />
                      </div>
                    </div>
                    {/* <div className="col-md-12 col-xs-12">
									<h4 className="midhead">Mason Bank Or UPI Details  </h4>
								</div> */}
                    <div className="col-md-12 col-sm-12 col-xs-12">
                      <div className="form-group has-float-label style-two">
                        <div className="h34">
                          {bankDetailShow === "bankdetails" ? (
                            <label className="radio-inline">
                              <input
                                id="chckbanking"
                                name="PaymentMode"
                                type="checkBox"
                                checked={bankDetailShow === "bankdetails"}
                              />
                              <span> Bank Details</span>
                            </label>
                          ) : (
                            ""
                          )}
                          {upiDetailsShow === "upidetails" ? (
                            <label className="radio-inline">
                              <input
                                id="chckupi"
                                name="PaymentMode"
                                type="checkBox"
                                checked={upiDetailsShow === "upidetails"}
                              />{" "}
                              <span> UPI Details</span>
                            </label>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    {bankDetailShow === "bankdetails" ? (
                      <div className="bankdetails" id="bankdetails">
                        <div className="col-md-3 col-sm-12 col-xs-12">
                          <div className="form-group has-float-label style-two">
                            <label>Account Holder Name </label>
                            <input
                              autoComplete="nope"
                              className="form-control"
                              type="text"
                              value={holderName}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-12 col-xs-12">
                          <div className="form-group has-float-label style-two">
                            <label>Account Type </label>
                            <input
                              className="form-control"
                              type="text"
                              value={accountType}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-12 col-xs-12">
                          <div className="form-group has-float-label style-two">
                            <label>Bank Name </label>
                            <input
                              className="form-control"
                              type="text"
                              value={bankName}
                              disabled
                            />
                            <span
                              className="field-validation-valid errormsgr"
                              data-valmsg-for="BankName"
                              data-valmsg-replace="true"
                              style={{ color: "red" }}
                            ></span>
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-12 col-xs-12">
                          <div className="form-group has-float-label style-two">
                            <label>Account Number </label>
                            <input
                              autoComplete="nope"
                              className="form-control"
                              data-val="true"
                              data-val-required="Account Number Required"
                              id="txtacntno"
                              maxLength="20"
                              name="AccountNumber"
                              type="text"
                              value={accountNo}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-12 col-xs-12">
                          <div className="form-group has-float-label style-two">
                            <label>IFSC Code </label>
                            <input
                              autoComplete="nope"
                              className="form-control"
                              maxLength="11"
                              minLength="11"
                              type="text"
                              value={ifsc}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-12 col-xs-12">
                          <div className="form-group has-float-label style-two">
                            <label>Cheque/Passbook </label>
                            <Link to={cancelChk} target="_blank">
                              <span>
                                <i class="fa fa-eye">View</i>
                              </span>
                            </Link>
                            <input
                              className="form-control"
                              id=""
                              name=""
                              type="file"
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {upiDetailsShow === "upidetails" ? (
                      <div id="upidetails">
                        <div className="col-md-3 col-sm-12 col-xs-12">
                          <div className="form-group has-float-label style-two">
                            <label>UPI ID </label>
                            <input
                              autoComplete="nope"
                              className="form-control"
                              type="text"
                              value={upiId}
                              disabled
                            />
                          </div>
                        </div>
                        {/* <div className="col-md-3 col-sm-12 col-xs-12">
											<div className="form-group has-float-label style-two">
												<label>UPI ID Screenshot *</label>
												<input className="form-control" id="" type="file" onChange={handleUpiIdScreen} />
											</div>
										</div> */}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        ""
      )}
      <Link
        id="scrollUp"
        to=""
        className={`scroll-to-top-button ${isVisible ? "visible" : "hidden"}`}
        style={{ position: "fixed", zIndex: "2147483647" }}
        onClick={scrollToTop}
      >
        <FontAwesomeIcon icon={faChevronUp} />
      </Link>
      <Footer />
    </>
  );
}
